import { template as template_66c22bf6aa0e4afba5a47121258f5f31 } from "@ember/template-compiler";
const FKLabel = template_66c22bf6aa0e4afba5a47121258f5f31(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
