import { template as template_d74712e9b66d4e5686b7a39d4db941e3 } from "@ember/template-compiler";
import { LinkTo } from "@ember/routing";
import { or } from "truth-helpers";
import dIcon from "discourse-common/helpers/d-icon";
import { i18n } from "discourse-i18n";
export default template_d74712e9b66d4e5686b7a39d4db941e3(`
  <LinkTo class="btn btn-flat back-button" @route={{@route}}>
    {{dIcon "chevron-left"}}
    {{i18n (or @label "back_button")}}
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
